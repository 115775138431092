/* eslint-disable react/prop-types */
import React from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { navigate, Link } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import H1 from '../components/header1';
import ImageAndTextComponent from '../components/voices-of-vow/ImageAndTextGridComponent';
import { COLOR_CORAL } from '../utils/constants';

const useStyles = makeStyles(theme => ({
  gridContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 60,
    marginBottom: '40px',
    '& h3': {
      marginTop: 0,
      maxWidth: 600,
      marginBottom: '1rem',
    },
  },
  logoBandPosition: {
    marginBottom: '-95px',
  },
  image: {
    maxHeight: 390,
    marginBottom: '-2rem',

    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  title: {
    paddingTop: 40,
    paddingBottom: 40,
    maxWidth: 750,
    '@media (max-width:600px)': {
      fontSize: 20,
      width: '100%',
      marginBottom: 26,
    },
  },
  textGrid: {
    padding: '0px 0px 40px 160px',
    maxWidth: 720,
    '@media (max-width:1024px)': {
      padding: '0px 0px 40px 100px',
    },
    '@media (max-width:900px)': {
      padding: '0px 0px 40px 50px',
    },
    '@media (max-width:600px)': {
      padding: '20px 20px 20px 20px',
    },
    '& p': {
      maxWidth: 600,
    },
    '& h3': {
      marginTop: 0,
      paddingTop: '2rem',
      maxWidth: 600,
      marginBottom: '1rem',
    },
    '& a': {
      color: COLOR_CORAL,
      textDecoration: 'none',
    },
    '& iframe': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
  },
  listDetailContainer: {
    display: 'flex',
  },
  listDetailTitle: {
    margin: 0,
    fontWeight: 'bold',
    width: 120,
  },
  listDetailText: {
    margin: 0,
    paddingTop: 2,
  },
}));

const VacancyTemplate = ({ data }) => {
  const classes = useStyles();
  const employeeImage = data.employeeImage.childImageSharp.fluid;

  const {
    area,
    datePublished,
    jobTitle,
    shortDescription,
    weCanOffer,
    workLocation,
    employer,
    reportingTo,
    applyBefore,
  } = data.allContentfulVacancyHr.edges[0].node;

  const keyTasks =
    data.allContentfulVacancyHr.edges[0].node
      .childrenContentfulVacancyHrKeyTasksAndResponsibilitiesRichTextNode[0]
      .json;

  const experience =
    data.allContentfulVacancyHr.edges[0].node
      .childContentfulVacancyHrQualificationsAndExperienceRichTextNode.json;

  const sendTo =
    data.allContentfulVacancyHr.edges[0].node
      .childContentfulVacancyHrSendApplicationToTextNode.childMarkdownRemark
      .html;

  const onClickWorkWithUsButton = () => {
    navigate('/about-us/work-with-us');
  };

  const workWithUsButton = {
    text: 'All available positions',
    onClick: onClickWorkWithUsButton,
  };

  return (
    <>
      <Container maxWidth="xl" style={{ paddingLeft: 0, paddingRight: 0 }}>
        <Container maxWidth="xl">
          <H1 title="WORK WITH US" />
        </Container>
        <Grid container className={classes.gridContainer}>
          <Grid item xs={12} sm={6} className={classes.textGrid}>
            <h3 className={classes.title}>{jobTitle}</h3>
            <p>{shortDescription.shortDescription}</p>
            <br />
            <h4>Key tasks and responsibilities</h4>
            {documentToReactComponents(keyTasks)}
            <br />
            <h4>Qualification and experience</h4>
            {documentToReactComponents(experience)}
            <br />
            <h4>We can offer</h4>
            <p>{weCanOffer.weCanOffer}</p>

            <br />
            <h4>Send application to</h4>
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: sendTo,
              }}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: 60,
                marginTop: 60,
              }}
            >
              <div className={classes.listDetailContainer}>
                <p className={classes.listDetailTitle}>Employer</p>
                <p className={classes.listDetailText}>{employer}</p>
              </div>
              <div className={classes.listDetailContainer}>
                <p className={classes.listDetailTitle}>Area</p>
                <p className={classes.listDetailText}>{area}</p>
              </div>
              <div className={classes.listDetailContainer}>
                <p className={classes.listDetailTitle}>Reporting to</p>
                <p className={classes.listDetailText}>{reportingTo}</p>
              </div>
              <div className={classes.listDetailContainer}>
                <p className={classes.listDetailTitle}>Location</p>
                <p className={classes.listDetailText}>{workLocation}</p>
              </div>
              <div className={classes.listDetailContainer}>
                <p className={classes.listDetailTitle}>Apply before</p>
                <p className={classes.listDetailText}>{applyBefore}</p>
              </div>
              <div className={classes.listDetailContainer}>
                <p className={classes.listDetailTitle}>Posted</p>
                <p className={classes.listDetailText}>{datePublished}</p>
              </div>
            </div>
            <Link to="/about-us/work-with-us" className={classes.link}>
              Back to available positions
            </Link>
          </Grid>
          <ImageAndTextComponent
            fluid={employeeImage}
            alt="employye image"
            bodyText="Visit our recruitment page and see all open positions or submit an
              open application."
            button={workWithUsButton}
          />
        </Grid>
      </Container>
    </>
  );
};

export default VacancyTemplate;

export const pageQuery = graphql`
  query VacancyBySlug($slug: String!) {
    allContentfulVacancyHr(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          jobTitle
          shortDescription {
            shortDescription
          }
          weCanOffer {
            weCanOffer
          }
          area
          workLocation
          createdAt
          datePublished(formatString: "MMMM Do, YYYY")
          slug
          employer
          reportingTo
          applyBefore
          workLocation
          childrenContentfulVacancyHrKeyTasksAndResponsibilitiesRichTextNode {
            json
          }
          childContentfulVacancyHrQualificationsAndExperienceRichTextNode {
            json
          }
          childContentfulVacancyHrSendApplicationToTextNode {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    employeeImage: file(relativePath: { eq: "employee2.webp" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
