import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Img from 'gatsby-image';
import { COLOR_CORAL } from '../../utils/constants';

const useStyles = makeStyles(theme => ({
  imageStyle: {
    padding: '20px 0px 40px 100px',

    [theme.breakpoints.down('lg')]: {
      padding: '20px 0px 40px 80px',
    },

    [theme.breakpoints.down('md')]: {
      padding: '20px 0px 20px 0px',
      borderBottom: 'none',
      marginBottom: '2rem',
      width: '100%',
    },

    [theme.breakpoints.down('xs')]: {
      // display: 'none',
    },
  },
  image: {
    maxHeight: 390,
    margin: '0px 0px 40px 20px',
    '@media (max-width:1300px)': {
      margin: '0px 0px 0px 80px',
    },
    '@media (max-width:900px)': {
      padding: '0px 0px 40px 50px',
    },
    '@media (max-width:600px)': {
      padding: '0px 0px 20px 0px',
      margin: '0px 0px 0px 0px',
    },
  },
  textStyle: {
    padding: '20px 100px 40px 100px',
    '& p': {
      maxWidth: 600,
    },
    '& h3': {
      marginTop: 0,
      maxWidth: 600,
      marginBottom: '1rem',
    },
    '& a': {
      color: COLOR_CORAL,
      textDecoration: 'none',
    },
    '& iframe': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },

    padding: '0px 10px 40px 100px',
    '@media (max-width:900px)': {
      padding: '0px 0px 40px 50px',
    },
    '@media (max-width:600px)': {
      padding: '0px 20px 20px 20px',
    },
  },
  textButton: {
    borderRadius: '0px',
    boxShadow: 'none',
    fontSize: '12px',
    textTransform: 'none',
    fontFamily: 'Arial',
    color: 'white',
    padding: '8px 20px 8px 20px',
    backgroundColor: COLOR_CORAL,
    '&:hover': {
      backgroundColor: COLOR_CORAL,
      boxShadow: 'none',
    },
    '@media (max-width:800px)': {
      marginTop: 20,
    },
  },
}));

const ImageAndTextComponent = ({ bodyText, button, fluid, alt }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} sm={6}>
      <div className={classes.imageStyle}>
        <Img fluid={fluid} alt={alt} className={classes.image} />
      </div>
      <div>
        <div className={classes.textStyle}>
          <h3>Would you like to join us and unleash your full potential?</h3>
          <p>{bodyText}</p>
          <br />
          {button && (
            <Button
              variant="contained"
              onClick={button.onClick}
              className={classes.textButton}
            >
              {button.text}
            </Button>
          )}
        </div>
      </div>
    </Grid>
  );
};

export default ImageAndTextComponent;
